var html = require('choo/html')

var illustrations = { local, network, sustainability }

module.exports = method

function method (props) {
  return html`
    <div class="Method">
      ${props.label || props.title ? html`
        <h2 class="Method-title">
          ${props.label ? html`<span class="Method-label">${props.label}</span>` : null}
          ${props.title || null}
        </h2>
      ` : null}
      ${props.items ? props.items.filter((item) => illustrations[item.image]).map((item) => html`
        <article class="Method-item">
          ${illustrations[item.image]()}
          <div class="Method-content">
            <h3 class="Method-heading">${item.heading}</h3>
            ${item.body}
          </div>
        </article>
      `).filter(Boolean) : null}
    </div>
  `
}

function local () {
  return html`
    <svg class="Method-image Method-image--local" width="402" height="402" viewBox="0 0 402 402">
      <g fill="none" fill-rule="evenodd">
        <circle cx="163" cy="193" r="142" fill="#D5CEBA" fill-rule="nonzero"/>
        <path fill="#646464" fill-rule="nonzero" d="M117.3 242c-.5 0-1-.2-1.3-.6-4-5.1-8.9-9.5-14.2-13-.5-.4-.8-1-.8-1.7 0-.6.4-1.2 1-1.5.5-.3 1.1-.3 1.7 0a65.8 65.8 0 0 1 14.9 13.9c.4.5.5 1.2.2 1.9-.2.6-.9 1-1.5 1z"/>
        <path d="M204.4 160.7c-3.4-.7-6.8-1.5-10.3-1.6l10.3 1.6zM261 200.6l2.6-.7-2.6.7z"/>
        <path fill="#345150" fill-rule="nonzero" d="M292.4 173.3c-4.7-3.9-11.5-5-17.4-3.1-4 1.2-7.8 3.9-12.1 4.2-2.6.2-5.1-.5-7.7-.5-4.9 0-9.6 2.5-13.2 5.8a60.6 60.6 0 0 0-10.6 13.8c-4.4 11.8 16.4 12.5 22.8 9.8l5-2.1a30 30 0 0 1 4.4-1.3l2.3-.3c6.7-.4 13.2 2.6 19.8 1.8 6.1-.7 11.4-5.7 12.7-11.7a17 17 0 0 0-6-16.4z"/>
        <g fill-rule="nonzero">
          <path fill="#BA6740" d="M194.1 159.1c5.2 21.9 8.7 29.1 17.8 49.6 6 13.5 12.8 26.7 16.7 40.9 2 7 3.1 14.2 4.3 21.4 4.2 26 8.4 55.2 6 81.4l18.7.4c1.1-10.7-1.8-21.4-2-32.1-.2-4.5 0-9.1-.5-13.6-.6-3.7-1.7-7.3-2.9-11l-8.2-25.7c-.6-6.1 1-13 3.1-18.8 1.4-3.5 3.1-6.8 4.4-10.2 1.8-5.1 4.1-9.8 5.3-15 1.8-8.6 3.7-18.4 6.9-26.5l-2.7.7-1.8.6a43.4 43.4 0 0 0-4.4 11.6c-.3 2-.4 4-.8 6a36 36 0 0 1-3.5 9.5 117 117 0 0 1-10.2 17.6c-.6.8-2.2 4-2.4 5.7-3.1-9.3-6.5-18.5-10.4-27.5-6.2-14.1-13.8-27.7-18.2-42.4-1.4-4.5-2.5-9-3.5-13.6-1.9-8.1-1.4-7-1.4-7.4-3.4-.7-6.8-1.5-10.3-1.6z"/>
          <path fill="#345150" d="M274.2 114.4c-1.2-10.3-10.8-16.2-19.4-19.5-7-2.7-14.6-4.9-21.7-2.6-6.7 2.2-13.2 8.1-19.7 5.3-2.8-1.2-4.9-3.8-7.4-5.4-7.5-4.6-16.5 0-24 4.6-5 3.2-10.3 6.4-14.2 11.3-3 3.8-5.7 9-10.2 10-1.5.3-3 .1-4.4.2-4.3.1-8.3 2-12 4.2a26.2 26.2 0 0 0-10 9.4c-2.3 4.2-2.9 10-.4 14 1.1 2 2.8 3.4 4.5 4.7 8.4 6.1 18.2 10 28.5 11.3 9.5 1.1 17.8-1.4 27.1-2.6 1-.1 2.2-.2 3.2-.1 3.5 0 6.9.8 10.3 1.5 2.7.5 5.5 1 8.4.8 18.2-.7 37.2-11.3 50.8-24.6 6-5.8 11.6-13.7 10.6-22.5z"/>
        </g>
        <path fill="#3D312D" fill-rule="nonzero" d="M361 346.7l-.6-6.8-1.3-9.8c-1.2-7.9-2.5-17.8-3-25.3v-.8l-12.1.7.2 1c1.8 7.4 3.4 15 4.9 22.5 1 5.1 2 10.4 3.2 15.6.3 2.7.3 5.3-1.4 7 0 .2-.2.3-.3.4l-.9 1a2 2 0 0 0 .2 2.5l.2.1h.2c.4.2.9.2 1.3.2 1.6 0 3.1-.5 4.5-1.4 1.5-1 2.7-2.3 3.8-3.5l.9-1.3c.2-.7.3-1.4.1-2zM339.7 303.4h-.9l-7.3-1.2-.8-.1-.8-.1c-1 8.6-.5 17.7-.2 26.6.2 4.6.4 9.4.4 14l.1 3.4v.5a21 21 0 0 0-6.6 4c-.3.3-.6.8-.6 1.2 0 .3.1.6.4.8.2.3.5.4.8.4 2 .3 4.1 0 6-.9l1-.5 1.4-.7.6-.2c.6-.1 1.2-.4 1.7-.7 1.3-.9 2-3.1 2.1-4.4 2-11 3.8-28.5 2.7-42.1z"/>
        <g fill-rule="nonzero">
          <path fill="#3D312D" d="M360.7 252.8c.4-3.5.1-7-.6-10.3-.6-2.6-.5-4.6-1.6-7l-8.5 2.3 1.6 5.9c1 3 2 6 3.5 8.2-2.4 9-7 28-3 28.7 5.4 1 5.7-5.9 6-12 .1-2.4.2-4.6.6-6.3.7-3.2 1.7-6.3 2-9.5z"/>
          <path fill="#FF9200" d="M359.2 287.5a49.4 49.4 0 0 1-14.2-5.7l-5.4-3.7c-4.3-3-9.2-5-14.1-6.7l-.2 1c-1.5 10-1.8 20-.8 29.9 5.8-.3 12 1.2 17.6 2.2 6.3 1 12.3-.7 18.6-1.6l-1.3-15.5h-.2zM329.7 251.5a73.8 73.8 0 0 1 19.3-6.6c1-.2 2-.4 2.5-1.2h.1c-.7-2.1-1.2-4.1-1.6-5.9l8.5-2.3c-1.8-7.4-4.8-8.4-7.5-11.9-2.9 2.7-8 3.5-11.8 1.9l-2-1-2.8-.6c-4.2 5.1-6 9.2-8 14l5 1.6c.4 5.3-.6 7.7-1.7 12z"/>
          <path fill="#FFCD00" d="M352.1 280.6c-4-.7.6-19.7 3-28.7a37.5 37.5 0 0 1-3.5-8.2c-.6.8-1.7 1-2.6 1.2a73.3 73.3 0 0 0-19.3 6.6l-.5 2.4c-1.1 5-2.3 9.9-3.3 14.9l-.4 2.6c5 1.7 9.8 3.8 14.1 6.7l5.4 3.7a49.4 49.4 0 0 0 14.2 5.7h.2c-.5-6.5-1-13-1.3-19-.3 6.2-.6 13.1-6 12.1z"/>
          <path fill="#3D312D" d="M339.1 203.3c1 .5 1.8.4 2.8.4.7 0 1.4 0 2-.3.9-.3 1.6-.8 2.1-1.5 1-1.7.9-1.5.8-3.3 0-.4-.5-1.1-.7-1.5-.9-1.6-4.3-2.4-6-1.5a5 5 0 0 0-2.3 3c-.4 1.3-.5 1.3-.3 2.6.3.9.8 1.6 1.6 2.1zM339.2 225.5c3.9 1.6 9 .8 11.8-1.9-1-1.3-4.2-3-5.9-3.6v-.2c.6-.3 1.2-.8 1.7-1.3 1.8-2 2.4-4.6 2.3-7 0-1.4-.2-2.7-.9-3.8-.7-1.1-1.7-2-2.8-2.8-1.5-1-2.4-.8-5.4-.1-1 .2-3 2.5-3.9 4.4-1 2.4-1 5-.4 7.6a6 6 0 0 0 3.2 3.5v.2l-4.5 3.4c.6.2 2.3.4 2.9.7l2 .9zM325.5 271.4c0-.9.2-1.7.4-2.6l3.2-15 .6-2.3c1.1-4.3 2.1-6.7 1.8-12l-5-1.5-1.4 3.4a54.6 54.6 0 0 0-3 15.4c-.3 5.2 1 10.4 2.4 15.5l.8.1.2-1z"/>
        </g>
        <path fill="#D8623B" fill-rule="nonzero" d="M327.1 260.6L353 257l-2 9-25-.5z"/>
        <g fill-rule="nonzero">
          <path fill="#3D312D" d="M291 330.3c-.4 5.6-.2 11.2.6 16.8v.5l-.2 1.2c-.7.8-2 3.5-1.5 4.6.3.6 1 1 1.7.9.5 0 1-.1 1.5-.4l1.5-1 .6-.5c.5-.3.8-.6 1-1 .5-1.2.8-2.6.7-4 .4-4.6.5-9.3.6-13.9v-2.4c-2.1-.4-4.3-.8-6.4-1v.2zM310 350.2l-1.7-1.5c.3-2.7 0-5.9-.1-9a63 63 0 0 1-.2-8.9l-.8.2h-.3l-.4.2-1 .1-.5.1h-.1-.2l-2.3.2h-.9c-.2 3.4.4 8.2 1 12.3l.7 6V350.5c.5.9 1.1 1.7 1.8 2.5.6.6 1.2 1 1.9 1.4l1.3.2c.5 0 1 0 1.3-.3 1-.4 1.5-1.5 1.3-2.5-.1-.6-.4-1.1-.9-1.6zM306.5 318.3h.1c4.3-5 4-11.5 2-17l-3.9 1.4c-.2 4.7.5 10.3 1.8 15.6z"/>
          <path fill="#0048A9" d="M306.5 331.2l-1 .1 1-.1zM304.8 329c1.2 0 2.2.8 2.4 2a20.1 20.1 0 0 0 3.8-1.3 54 54 0 0 1-6.3-27l3.8-1.4a28 28 0 0 0-.7-1.8c-.3.2-.7.2-1 .2-2.6 0-3.1-3.3-1.5-4.5l-.2-.3c-4 1-8.1 1-12-.2a29.6 29.6 0 0 0-4 6.3l4.3 1.8c-1.2 6-2.7 9.1-3.9 13.9l-.3 1.1-.9 5.8c-.3 2.2.2 5.3 0 6.4l2.8.1c2.1.2 4.3.6 6.4 1l.8.1 3.2.4h1c-.1-1.3.7-2.6 2.3-2.6zm-8.3-32.1c3.2 0 3.2 5 0 5s-3.2-5 0-5zm-1.1 28.6c-.2.5-.5 1-.9 1.3-.4.3-1 .5-1.5.5h-.2c-.7 0-1.3-.2-1.8-.7a2.5 2.5 0 0 1 .4-3.9l.3-.2a2.5 2.5 0 0 1 3.4.9c.3.4.4.8.4 1.3l-.1.8zm2.8-8.4c-.5.5-1.1.8-1.8.8s-1.4-.3-1.8-.8a2.5 2.5 0 0 1 0-3.5c.4-.5 1-.8 1.7-.8h.1c.7 0 1.3.3 1.8.8a2.5 2.5 0 0 1 0 3.5zm3-13c3.3 0 3.3 5 0 5-3.1 0-3.1-5 0-5zm-.5 18.2c-.1-.7 0-1.4.3-2 .1-.3.4-.5.6-.7.3-.2.6-.4.9-.4a3 3 0 0 1 1.6 0c.5.1.8.4 1.1.7l.4.5c.4.6.5 1.3.3 2-.2.5-.6 1-1.2 1.4-.5.3-1.1.4-1.7.3h-.5l-.6-.3c-.6-.4-1-1-1.2-1.5z"/>
          <path fill="#3D312D" d="M305.1 294.9c-.8-1.3-3.4-3.3-3.9-4v-.2l.2-.2c2.8-2.5 3.3-9-1-10.2h-.2l-.3-.1c.8-.6 1.3-1.6 1.3-2.7-.2-1.7-1.5-3-3.2-3.1-.7 0-1.4.2-2 .7-.2.3-.5.7-.6 1.2-.3.7-.5 1.6-.1 2.3a10.4 10.4 0 0 0 2.4 1.7c-3 1-5.5 4.6-4 7.7.4.9 1 1.6 1.8 2.2l.6.5-.2.6c-.9 1.3-1.9 2.2-2.9 3.4 4 1.1 8.1 1.2 12.1.2zM293.4 302.9L289 301a20.2 20.2 0 0 0 .1 16.9l.3-1.2c1.2-4.8 2.7-8 3.9-13.8z"/>
          <path fill="#92BCE5" d="M293.6 322.2c-.6-.1-1.3 0-1.9.3a3 3 0 0 0-.3.2l-.4.3a2.5 2.5 0 0 0 0 3.6c.5.5 1.1.7 1.8.7h.2c.5 0 1-.2 1.5-.5a2.6 2.6 0 0 0 1-2c0-.6-.2-1-.4-1.4-.3-.6-.8-1-1.5-1.2zM303 324.1c.6.1 1.2 0 1.8-.3.5-.3 1-.8 1.1-1.5.2-.6 0-1.3-.3-2l-.3-.4c-.3-.3-.7-.6-1.1-.7a3 3 0 0 0-1.7 0c-.3 0-.6.2-.8.4a2 2 0 0 0-.7.7 2.5 2.5 0 0 0 .9 3.5l.6.2.5.1z"/>
          <path fill="#FFF" d="M296.4 312.8h-.1c-.7 0-1.3.3-1.8.8a2.5 2.5 0 0 0 0 3.5c.5.5 1.1.8 1.8.8h.1c.7 0 1.3-.3 1.8-.8a2.5 2.5 0 0 0 0-3.5c-.5-.5-1.1-.8-1.8-.8z"/>
          <path fill="#92BCE5" d="M301.3 304.1c-3.2 0-3.2 5 0 5s3.2-5 0-5zM296.5 301.9c3.2 0 3.2-5 0-5s-3.2 5 0 5z"/>
          <path fill="#FFF" d="M306.8 299.7c.3 0 .7 0 1-.2a24 24 0 0 0-1.2-2.2v-.2l-.5-.7v-.1a16 16 0 0 0-.8-1.1c-1.6 1.2-1.1 4.5 1.5 4.5zM304.8 329c-1.6 0-2.4 1.3-2.4 2.6l2.3-.1H305l.5-.1 1-.2h.4l.3-.1a2.3 2.3 0 0 0-2.4-2z"/>
        </g>
        <path fill="#FFF" fill-rule="nonzero" d="M334.4 230.2c4.3 1.7 9 2.2 13.6 1.5 1.8-.3 7-2 7-2.1l-.5-.7c-1.3-1.8-4-4.9-4-4.9l-2.2 1.7c-1.6.9-3.4 1.4-5.2 1.4-3.5-.2-4.3-1.1-6.5-2.1-.8-.3-1.7-.5-2.6-.6a26.5 26.5 0 0 0-3 4.4c-.2.5 2.7 1.1 3.4 1.4zM328.2 302.5c2.5.2 5 .5 7.6 1l6.9 1.2c2.6.4 5 .4 8.2 0 2.9-.3 6-.9 10.1-1.6l-.5-6c0-.4-2.3.1-2.7.2l-8.6 1.5c-2.9.5-5.8 1-8.6.3-4.6-1.4-11.1-5-15.6-1.8a222 222 0 0 0 .4 5.2h2.8z"/>
        <path d="M120 274c-.6.3-1 .7-1.3 1.2-.4 1-.1 2.5.9 5 .6 1.3 2.3 3 3.8 3.2-.2-1.2-.5-2.4-1-3.6l-.4-1.4-2-4.4zM128.2 270.4l1.3 4.3.5 1.3c.4 1.1 1 2.1 1.6 3 .8-1.3 1.2-2.9 1-4.5h-.4l-1-.7c-.6-.7-2-2-2-3.1l-1-.3z"/>
        <path fill="#3D312D" fill-rule="nonzero" d="M174.5 302l5.7 43.4c0 1.5 1.3 2.4 2.2 3l.5.4c2.2 1.7 4.9 2.7 7.7 2.9h.6l.2-.6c.2-.5.6-1.8-.3-2.7l-.7-.4c-1.4-.8-3.1-3.5-3.6-5.3v-41l-12.3.3zM151.7 311.6l-3.3 32.2a31 31 0 0 0-4.2 3.3l-1.9 1.6c-1 .8-.6 1.6-.5 1.8l.2.4h.4l1.3.1c1.7 0 3.2-.4 4.6-1.2 1.8-.9 3.3-2.1 4.6-3.5.5-.4 1-1 1.1-1.6l1.6-10 7.6-22.1 3.1-10.4-14.6.4v9z"/>
        <path fill="#646464" fill-rule="nonzero" d="M125 234.2c.6 0 1.1-.3 1.4-.8.3-.6.3-1.2 0-1.7a69.3 69.3 0 0 0-7.4-10.4 1.7 1.7 0 0 0-2.9.5c-.2.6 0 1.2.4 1.7 2.6 3 5 6.4 7 9.9.3.5.9.8 1.5.8z"/>
        <path fill="#3D312D" fill-rule="nonzero" d="M166.8 201c.4.2.9.3 1.3.3 1.6 0 3.3-.5 4-1.9.5-.8.5-1.9.4-2.8 0-.5 0-1-.2-1.4a3.5 3.5 0 0 0-6.7.8c-.2 1.2 0 2.4.3 3.5.2.6.5 1 1 1.4z"/>
        <path fill="#2643EF" fill-rule="nonzero" d="M181.6 248.8l-.7-1.7.7 1.7zM162.4 236.6c-.3.3-.3.3 0 0zM154.1 274l-.3 1 .3-1zM196.6 301.4c.2-1.9.2-3.8 0-5.7.1 1.9-1.8 2.9-3.6 3.9-1.9 1-3.9.5-5.9.2-2-.2-3.6 0-5.5-1-1.8-1-2.8-2.1-3.8-3.7-1-1.8-1.8-3.5-1-5.5.7-1.8 2.3-3.1 3.8-4.3 3.7-2.8 7.6-2.6 12-2.3 1.4.2 2.2 1.6 2.7 3.1a93.1 93.1 0 0 0-3.7-12.1c-2.8-7.6-6.6-16.5-9.7-24.4 1.2 3.1 1.7 5.8-1.2 7.5-2.4 1.3-5.5 0-7.5-1.5-2.3-1.7-3.8-4.6-2-7.3 1.6-2.6 4.6-3 7.4-3 1.7 0 1.7 0 2.1 1.3.4 0 5.6-.8 8.2-2l.3-.2-.9-2.2c-1-2.6-2-5.3-3.7-7.7l-3.2-5c-.9-1.1-1.9-1.6-2.6-2.1-.8.3-2.8.3-3.3.3l-11.2-.5c-1 .5-1.8 1-2.7 1.8 2-1 4-1.4 5.8.4.6.6.9 1.4.7 2.2l.5 1.2c.5 1.5-.2 3-1.2 4.2a7.4 7.4 0 0 1-7.3 2.1c-1.7-.5-3-2-2.6-3.8v-.2-.5c0-.8.3-1.6.7-2.3-1 1-1.9 2.2-2.7 3.3l-5 7.2a21 21 0 0 1 5.6 5.6l4.3-4.2c.5 1.2.9 2.5 1 3.8a14 14 0 0 1-.8 5.5l-5 16c.6-1.3 1.2-2 2.3-2 3.6.2 7.3.5 10.9 1.1 2.9.5 5.8 1.8 6 5.1.2 3-1.6 6-3.4 8.3-2 2.6-4.6 4.6-7.3 6.5-1.3.9-2.6 1.9-4.2 2.3-1.6.4-3.6.4-5.3.3-3-.1-5.5-2.5-4.5-5.4l.8-2.3c-2.4 7-4.8 13.7-7.2 19.4l8-.2 14.6-.4 8.2-.2 12.3-.3 9.8-.3z"/>
        <path fill="#2643EF" fill-rule="nonzero" d="M151.8 281a280 280 0 0 1-.4 1l.4-1z"/>
        <path fill="#3D312D" fill-rule="nonzero" d="M180.7 246.6l.2.5.7 1.7.3.8c3.1 7.9 6.9 16.8 9.7 24.4a118 118 0 0 1 2.2 6.8 56.3 56.3 0 0 0-1-27l-2-5.5-1.6-3.9-.3.1c-2.5 1.3-7.8 2-8.2 2zM178.8 227.3a44.1 44.1 0 0 1-1.7-1.7c-.6-.7-2.8-4.4-3.2-5.7l.6-1.2a19.6 19.6 0 0 0 .8-12.3c-.9-2-2.8-3.5-4.7-4.6-2.2-1.3-3.7.2-5.2 2-2.6 3.1-4.1 7.5-2.7 11.3.6 1.7 1.7 3.2 2.8 4.6l1.9 2c0 2.1-2.5 4.2-3 5.5l11 .5c.6 0 2.6 0 3.4-.4zM150.5 242.8L144 252a48 48 0 0 1-4 5.3c-1.7 1.7-3.5 3.2-5.1 4.9a28.5 28.5 0 0 0-4.5 6l-.5.9h.1l-.4 1.6-.3-.1c0 1.2 1.3 2.4 2 3 .2.3.5.6.9.7l.4.1v-.2l1.7-.3v.5c.6-.4 1.7-1.2 1.9-1.9.7-2.3 0-5 1.4-7 1.1-1.7 2.7-3 4.2-4.3 4.7-4 9.6-8.5 14.3-12.9a20.4 20.4 0 0 0-5.6-5.6z"/>
        <path fill="#FFF" fill-rule="nonzero" d="M192.6 283c-4.4-.3-8.3-.5-12 2.3a10.8 10.8 0 0 0-3.8 4.3c-.8 2 0 3.7 1 5.5 1 1.6 2 2.8 3.8 3.7 1.9 1 3.5.8 5.5 1 2 .3 4 .8 5.9-.2 1.8-1 3.7-2 3.7-3.9v-.4l-.2-2v-.5l-.1-.7-.2-.9v-.2a42.8 42.8 0 0 0-1-4.9c-.4-1.5-1.2-3-2.6-3z"/>
        <path fill="#F5E372" fill-rule="nonzero" d="M154.6 291.1c1.7 0 3.7.1 5.3-.3a32.6 32.6 0 0 0 11.5-8.8c1.8-2.3 3.7-5.3 3.5-8.3-.3-3.4-3.2-4.6-6.1-5-3.6-.7-7.2-1-10.9-1.2-1 0-1.7.6-2.3 2l-1.4 4.5-.4 1-2 6-.3 1-.4 1-.1.4-.8 2.3c-1 2.8 1.4 5.2 4.4 5.4z"/>
        <path fill="#DAA54F" fill-rule="nonzero" d="M181.6 248.8a26 26 0 0 1-.7-1.7l-.2-.5c-.4-1.3-.4-1.2-2-1.3-2.8 0-6 .4-7.5 3-1.7 2.7-.3 5.5 2 7.3 2 1.5 5.1 2.8 7.5 1.4 2.9-1.7 2.4-4.3 1.2-7.4l-.3-.9z"/>
        <path fill="#D8623B" fill-rule="nonzero" d="M157.5 235v.3c-.5 1.9.9 3.4 2.6 3.8 2.7.7 5.4-.1 7.3-2.1 1-1.2 1.6-2.6 1.2-4.2l-.5-1.1c.2-.9 0-1.7-.7-2.3-1.9-1.8-3.7-1.4-5.8-.4l-1.7 1.5-1.7 1.8a5.7 5.7 0 0 0-.7 2.8zm4.8 1.7c-.2.2-.2.2 0 0z"/>
        <path fill="#BA6740" fill-rule="nonzero" d="M132.6 274.3v.2c.2 1.6-.2 3.2-1 4.6-.7-1-1.2-2-1.6-3-.7-.3-.8-.2-1 0l-.5.5-.1.2c-.5.7-1.3 1.3-2.2 1.3-.3 0-.7 0-1-.2h-1c-.2 0-.4.2-.6.4l-.5.7-.6.8 1 3.6c-1.6-.2-3.3-2-3.9-3.3-1-2.4-1.3-4-.9-5 .3-.4.7-.8 1.2-1.1.7 1.4 1.5 2.9 2.1 4.4l.6-.8a3 3 0 0 1 1.3-.9h1.7l.6.2c.6 0 1-.6 1.2-.8v-.2c.3-.3.6-.7 1-.9a2 2 0 0 1 1-.3l-1.3-4.3c.4 0 .7.1 1 .3h.1l.4.1.4-1.6h-.1l-.2-.1c-.6-.2-1.7-.6-2.6 0l-7.2 3-.5.3-.3.1c-.8.5-1.5 1.2-2 2-.7 2 0 4.3 1 6.3.7 1.9 3 4.3 5.5 4.3 0 1 0 2.1-.2 3.1-.3 1.7.1 3.4.6 5 1.3 4 5 6.7 7.8 8.2.8.5 1.7.7 2.6.8h.2c1.5 0 2.9-1.2 3.8-3 2.1-4.3 1.8-9.3-.7-13.2-1-1.5-2.2-2.9-3.6-4.1l-1.4-1.5a9 9 0 0 0 1.6-5.9v-.5l-1.7.3z"/>
        <path fill="#FFF" fill-rule="nonzero" d="M129.5 274.7a2 2 0 0 0-1.2.3c-.3.2-.6.5-.8 1h-.1c-.2.3-.6.9-1.2.9-.2 0-.4 0-.6-.2h-1.7a3 3 0 0 0-1.3.9l-.6.8.5 1.4.6-.8.5-.7.6-.4h1l1 .2c1 0 1.8-.6 2.2-1.3l.1-.2.5-.6c.2 0 .4-.2 1 0l-.5-1.3z"/>
        <path fill="#345150" fill-rule="nonzero" d="M266.5 353h.1c1.2 0 2-.9 2-1.8-.7-6.5-.4-10.3 0-15 0-2.5.3-5.2.4-8.5 0-1-.9-1.7-2-1.7-1.2 0-2.1.7-2.1 1.6l-.5 8.3a80 80 0 0 0 0 15.6c0 .8 1 1.5 2 1.5zM273.6 353c.8 0 1.5-.6 1.6-1.5.2-1.6.8-3.6 1.3-5.5.6-2 1.3-4.2 1.5-6.1 0-.6-.2-1.2-.6-1.6-.5-.3-1.1-.4-1.7-.2-.5.3-.9.8-1 1.4 0 1.6-.7 3.6-1.2 5.5-.6 2.1-1.3 4.3-1.5 6.2 0 .4 0 .8.4 1.2.2.3.6.5 1 .6h.2zM213.3 352c-1 0-1.7-.7-1.7-1.6a8.9 8.9 0 0 0-1-4.2 54 54 0 0 1-.7-2l-.8-1.7c-1.2-2.8-2.8-6.3-1.8-9.3.3-1 1.3-1.4 2.2-1.1.9.3 1.3 1.3 1 2.2-.6 1.7.7 4.5 1.7 6.8l.8 1.9.8 1.8a12 12 0 0 1 1.2 5.4c0 1-.7 1.8-1.6 1.8h-.1zM221.2 352c-.9 0-1.6-.6-1.7-1.5l-1-6.2c-1.1-8-1.7-12.1-1.4-22.7 0-.6.4-1.1 1-1.4.5-.3 1.2-.3 1.7 0 .5.4.8 1 .8 1.5-.3 10.3.3 14.3 1.5 22.1l.9 6.3c.1 1-.6 1.8-1.5 1.9h-.3zM226 353c-.7 0-1.4-.5-1.6-1.3-.8-3.3-.2-6.4.4-9.6a27 27 0 0 0 0-13.8c0-.6 0-1.3.4-1.7.4-.5 1-.7 1.6-.6.6.2 1 .7 1.2 1.3 1.8 6.4.8 11.5 0 15.5-.6 3-1.1 5.5-.5 8a1.7 1.7 0 0 1-1.5 2.2z"/>
      </g>
    </svg>
  `
}

function network () {
  return html`
    <svg class="Method-image Method-image--network" width="402" height="402" viewBox="0 0 402 402">
      <g fill="none" fill-rule="evenodd">
        <circle cx="201" cy="194" r="142" fill="#D5CEBA" fill-rule="nonzero"/>
        <path d="M285 340.2zM216.2 215.2l.4-18c-8.5.2-16.5.5-25.5 1l-.4 35.3h25l.5-18.3zM251.8 249.1a8 8 0 0 1-.4-2.7v-10l-3.8 6.5a156 156 0 0 0 1.3 19.4h.4l1.8-1.2v-.3a104 104 0 0 1 2.7-9.4 5 5 0 0 1-2-2.3zM254.4 259.2z"/>
        <path fill="#BA6740" fill-rule="nonzero" d="M192.6 302.5c10.2.5 20.4.2 30.6-.6l.2-2.7c.4-5.5 1-10.1 1.3-15.6h-32c-.2 6.3-.2 12.6 0 19zm13.7-10c0-.4.3-.7.5-1 .4-.2.8-.3 1.2-.2.7 0 1 1 1 1.7.1.7-.3 1.4-1 1.7-.7.2-1.5-.1-1.8-.8-.1-.5 0-1 .1-1.4zM192.7 304.3c.1 7.7.4 15.4.7 23 0 .2 15.4.2 16.8.2 1.8 0 3-.4 4 .3l8.3 16.9.2-20.6c0-6.8.1-13.7.5-20.5a247.2 247.2 0 0 1-30.5.7zm14.2 11.2c0-.4.2-.7.5-1 0 0 0-.2 1.1-.2.8 0 1.1 1 1.1 1.7s-.3 1.4-1 1.7c-.7.3-1.5 0-1.8-.8v-1.4z"/>
        <path fill="#BA6740" fill-rule="nonzero" d="M129.9 270.7L106 270a597.4 597.4 0 0 0-.1 56.2l.7 14.4c.5-8.1 2.2-17.6 2.8-25.7.8-11.8 1-21.8 3.8-33.3 9.8.2 88.7 1.3 111.6.5.3-3.6.3-7.3.2-11-4.8.5-90.5-.2-95.1-.4z"/>
        <path fill="#FFF" fill-rule="nonzero" d="M208.6 317.7c.7-.3 1.1-1 1-1.7 0-.7-.3-1.7-1-1.7-1.2 0-1.2.2-1.2.2l-.5 1c-.2.5-.2 1 0 1.4.2.7 1 1 1.7.8zM208 294.7c.7-.3 1.1-1 1-1.7 0-.7-.3-1.7-1-1.7-.4 0-.8 0-1.2.2l-.5 1c-.2.5-.2 1 0 1.4.2.7 1 1 1.7.8z"/>
        <path fill="#0048A9" fill-rule="nonzero" d="M213.3 254.6a1 1 0 0 0 1.2-1 1 1 0 0 0-1-1.1c-4.5-.6-9-.8-13.7-.6a1 1 0 0 0-1 1.2c0 .6.6 1 1.2 1a76 76 0 0 1 13.3.5zM201.2 257.7a1 1 0 0 0-1 1c0 .7.4 1.1 1 1.1l21.5.4c.6 0 1.1-.5 1.1-1 0-.7-.5-1.1-1-1.1l-21.6-.4zM194.2 265.3a1 1 0 0 0 1 1.2 172.7 172.7 0 0 0 22.8-.8c.4 0 .7-.3.9-.7a1 1 0 0 0-.1-1 1 1 0 0 0-1-.5c-7.5.7-15 1-22.5.8a1 1 0 0 0-1.1 1z"/>
        <path fill="#D8623B" fill-rule="nonzero" d="M127.7 261c-.6 0 .1 2.2.3 2.7.8 2.5 2 4.8 3.5 7l26.5.3c.7-2.1 1.7-8 2-10.2a11 11 0 0 0-4.9-.6l-27.4.8z"/>
        <path fill="#345150" fill-rule="nonzero" d="M158 233.3c1.2 1.2 3.7 2 4.5 1.9l3.1-1c1-.5 1.9-1.3 2.5-2.2.2-.2.3-.4.3-.7 0-.7-.7-1.3-1.4-1.6 1.3 0 2.3-.2 3.4-.9.8-.6 1-1.5.9-2.7-.2-1.4-1.1-1.7-2.2-2.6 1-.5 1.8-1.3 2.3-2.4.3-.8.5-1.8 0-2.6-.1-.3-.4-.6-.8-.9a3.8 3.8 0 0 0-5.3 1.4c0-.5-.3-.9-.7-1.1-.4-.3-.9-.4-1.3-.4a3.8 3.8 0 0 0-3.8 4.4c-1.6-.7-3.6-.2-4.5 1.3a4 4 0 0 0-.3 2.6c0 .7.8 4.7 2 6.2a40.5 40.5 0 0 0-4.7 8.4h1.8c1.1-2.5 2.5-4.9 4.1-7zM133.7 221.1c2 6.8 3.8 14.2 4.1 19.1l1.7.1c-.3-5-2.2-12.7-4.2-19.6 3.6-1.4 7-5.6 7.5-7.8a7 7 0 0 0 0-3.6 4.2 4.2 0 0 0-2.2-2.8 3 3 0 0 0-3.3.7 5.5 5.5 0 0 0 .7-5.5c-.3-.8-1-1.5-1.7-2-.5-.1-1-.2-1.5-.1-1 0-2 .5-2.2 1.4-.3-2.3-.7-4.7-2.2-6.4a6 6 0 0 0-4.3-2c-.4 0-.8 0-1.1.2-.5.2-.9.6-1.1 1-1.8 2.7-1.4 6.2-.3 9.2-1.1 0-2 1-2.2 2.1 0 1.2.4 2.2 1 3.2s1.3 1.8 1.7 2.9c-1.5.3-3 1.2-3.2 2.7-.3 1.2.4 2.5 1.3 3.4 1.6 1.6 7.8 5 11.5 3.8z"/>
        <path fill="#345150" fill-rule="nonzero" d="M148 224.1l1.3-6 .1-.5.5-2 .2-3.2v-.5c0-2.3.2-4.6.5-6.9 2.8-.4 7.7-2.8 9.4-4.5.7-.7 1.3-1.6 1.7-2.5.5-1 .5-2 .2-3-.4-1-1.4-1.5-2.4-1.5 1.1-.7 2.1-1.6 3-2.6 1-1 1.4-2.4 1.3-3.8a3.9 3.9 0 0 0-4.7-3.4 8.4 8.4 0 0 0 .9-7.2c-.2-.6-.5-1-1-1.5-.6-.7-1.7-.9-2.7-.9-1.9 0-3.6.7-5 2a5.3 5.3 0 0 0-1.6 4.9c-1.7-.4-4-.1-4.5 1-1 2.1-.7 5.8-.2 8-.6-.2-1.3-.1-1.8.3s-.8 1-1 1.6c-.6 1.8-.3 3.8.8 5.5 1 1.6 3.5 5.8 5.9 7.3a64 64 0 0 0-.5 7.1v.6c0 1 0 2-.2 3l-.4 1.7-.2.6c-.5 2-1 4-1.2 6.2-.9 5.5-1.4 11-1.6 16.6h1.7c.2-5.5.7-11 1.6-16.4z"/>
        <path fill="#D8623B" fill-rule="nonzero" d="M129.2 239.8c3.1 4.8 3.4 14.9 6.1 19.8l19.5-.6c.4-5.8 2.7-14.5 5.8-19.4a68.7 68.7 0 0 1-14.1 1l-1.7-.1-5.3-.2h-1.7l-8.6-.5z"/>
        <path fill="#FFF" fill-rule="nonzero" d="M189 237h28.4c.9 0 1.7-.8 1.7-1.8a3042.6 3042.6 0 0 1 1-39.8 1.7 1.7 0 0 0-1.8-1.7c-9.8.2-18.7.6-29 1.2-.9 0-1.6.8-1.6 1.7l-.4 38.6c0 1 .7 1.7 1.7 1.7zm2.1-38.7c9-.6 17-1 25.5-1.1a2959.2 2959.2 0 0 1-1 36.3h-24.9l.4-35.2z"/>
        <path fill="#FF9200" fill-rule="nonzero" d="M251.4 246.4c0 1 .1 1.8.4 2.7a5 5 0 0 0 2 2.4c1 .6 2 1 3.2 1.4 4.6 1.5 18.9 2.3 23.7 2.2a24 24 0 0 0 .8-18.4h.5l5.6-1.8 1.8-.5A27.1 27.1 0 0 0 283 223c-2.2-2-4.2-2.7-6.3-4.4-1.2 1-8.7 1.3-10.2 1.3-4.5.2-5.1-1-9.6-1.6-5.3 1.6-9 7.2-12.2 11.2l1.3 1.3 5.5 5.5v10z"/>
        <path fill="#3D312D" fill-rule="nonzero" d="M281.6 236.7l.2.7a24 24 0 0 1 .7 12c-.4 2-1 3.9-1.7 5.7l-2.6 6.3-.4.1 2.2 3.5.3-.2c.9-1.9 2.4-4.1 3.9-6.3 1.3-2 2.7-4 3.4-5.7 1-2.1.9-4 .9-6.2 0-5.6 1-6.6-.8-11.5v-.1l-5.7 1.7h-.4z"/>
        <path fill="#005CD7" fill-rule="nonzero" d="M285 340.2l1.3-.1s4.5-.3 4.7-.2c.9 0 1.7 0 2.6-.2l-6.1-30c-.7-3.7-1.4-7.2-1.9-10.8a302 302 0 0 1-1.4-40.4c-1.5 2.2-3 4.4-3.9 6.3l-.3.2-2.2-3.5.4-.1 2.6-6.3c-5 0-19.2-.7-23.8-2.2-1.2-.3-2.2-.8-3.2-1.4a104 104 0 0 0-2.7 9.4v.3l.2-.1.2-.1.2-.2.9-.5.3-.2.6-.4h.1l.2-.2h.2l.2-.2.2-.1 4.2 12.2-9.3 5V289.1c-.1 17.8-3 35.4-2.3 53.2 1-.2 2-.4 3-.4l9 .1 3.9.3c2.1-11.7 4.2-23.6 3-35.4-.5-4.5-1.4-9-1.7-13.5-.4-6.3.6-12.7 2-18.9 1.9 11 4.9 22.2 8.1 32.9 3.3 10.7 6 21.7 5.5 32.9.8.4 2 .4 3.1.2l2-.3h.1z"/>
        <path fill="#3D312D" fill-rule="nonzero" d="M266.5 220c1.5 0 9-.3 10-1.2-1.5-1.6-6.7-6.2-7.5-7.8l.9-1c2.2-3.2 3-7 2.5-10.9-.4-2.9-2.5-9-6.3-8.6-4.2.5-6.3 6.3-7 9.7-.7 3.1-.8 6.5.8 9.2.5.7 1 1.3 1.7 1.8-.5 2.4-3.2 5.7-4.7 7.2 4.4.6 5 1.8 9.6 1.7z"/>
        <path fill="#F5E372" fill-rule="nonzero" d="M254.4 259.2h-.1l-.3.2-.2.1-.2.1-.1.1-.6.4-.3.2-.9.5-.2.1-.2.2h-.2l-1.8 1.1h-.5v.2c.2 1.1 1.4 2.3 1.7 3.4.2.7.3 1.5.3 2.2a4 4 0 0 1-.4 2.1c-.6.8-1.5 1.2-2.4 1.1-.9-.1-1.7-.5-2.4-1.1-.4-.2-.7-.6-1-1a22.4 22.4 0 0 1-.9-3.6l-5.2 3 3.8 10.9c.1.4 3.2-1.1 6.7-2.9l9.6-5.1-4.1-12.3z"/>
        <path fill="#3D312D" fill-rule="nonzero" d="M245.6 270.1c.7.6 1.5 1 2.4 1.1 1 .1 1.8-.3 2.4-1a4 4 0 0 0 .4-2.2c0-.7 0-1.5-.3-2.2-.3-1-1.4-2.3-1.7-3.4v-.1-.4c-.7-4-1.3-15.6-1.2-19l3.8-6.4-5.5-5.6-.3.3-1.8 2.3c-2.9 3.8-3.1 8.3-3.4 13 0 2.6.5 5.1 1 7.6 1 4 1.5 7.7 2.4 11.6.2 1.2.5 2.3.9 3.4.2.4.5.8 1 1zM297.6 345.8c-.2-.6-.7-1.1-1.3-1.5l-5.3-4.4-4.7.2-1.3.1h-.1l-2 .4 1.2 2.3c.3.7.7 1.3 1.2 2l1.4 1.3c2 1.4 4.4 2.3 6.9 2.5 1 .2 2 0 2.8-.4.9-.5 1.5-1.5 1.2-2.5zM262.8 188.9c.4.4 1 .8 1.5 1 1.8 1 4.2.3 5.6-1.2 1.4-1.5 2-3.7 1.7-5.8-.1-2-1.5-4.2-3.7-4.3-2 0-4.3 1.7-5.5 3.3-1.1 1.4-1.8 3.4-1.1 5 .4.8.9 1.4 1.5 2zM250 342c0 1-.6 1.8-1.8 2.1-1.2.4-1.4.7-2.4 1.5-.6.6-1 1.3-1 2-.2.7-.2 1.6.5 2l1 .1c2 0 4.3.1 6-.8a23.4 23.4 0 0 0 5.8-4.2c.5-.6.7-1.8.9-2.6l-9-.1z"/>
      </g>
    </svg>
  `
}

function sustainability () {
  return html`
    <svg class="Method-image Method-image--sustainability" width="402" height="402" viewBox="0 0 402 402">
      <g fill="none" fill-rule="evenodd">
        <circle cx="200" cy="209" r="142" fill="#D5CEBA" fill-rule="nonzero"/>
        <path fill="#FF9200" fill-rule="nonzero" d="M303.5 100c3.1.1 6.3-.6 9-2 3-1 5.5-2.7 7.6-5 2.2-2 4-4.6 4.9-7.5 1.4-2.7 2.1-5.9 2-9l-.9-6.3c-1-3.9-3.1-7.4-6-10.3l-4.7-3.7a23.4 23.4 0 0 0-11.9-3.2c-3.1-.1-6.3.6-9 2-3 1-5.5 2.7-7.6 5-2.2 2-4 4.6-4.9 7.5a17.7 17.7 0 0 0-2 9l.9 6.3c1 3.9 3.1 7.4 6 10.3l4.7 3.7c3.6 2 7.7 3.2 11.9 3.2z"/>
        <path fill="#3D312D" fill-rule="nonzero" d="M240 298.5l.1 1.6c.3.7.8 1.4 1.4 1.9 1.2 1.1 2.9 2 4.6 2 1.1-.1 2.2-.5 3.1-1.1.5-.3 1-.7 1.3-1.1.3-.6.5-1.3.5-2 0-2.3-.2-4.4-2.1-5.8-1-.7-2.8-1.3-4-.8-1.2.4-2.2 1-3 1.9-1 1-1.6 2.1-1.8 3.4zM286 237c-.5.1-1 .4-1.4.7-.4.4-.6.9-.6 1.4 0 .2.1.5.3.7.3.4.7.8 1.2 1 .5.2 1 .3 1.6.1.3 0 .5-.2.6-.4.2-.2.2-.4.2-.6 0-.4.5-3-1.8-2.9z"/>
        <path fill="#345150" fill-rule="nonzero" d="M310.8 306c-1 0-1.8-.8-1.8-1.7 0-2.4.6-4.9 1.5-7.2.2-.5.7-1 1.4-1 .6-.2 1.3 0 1.7.5.4.5.5 1 .3 1.6-.8 2-1.2 4.1-1.3 6.2 0 .9-.8 1.6-1.8 1.6zM305.9 307c-.9 0-1.6-.6-1.7-1.4-.7-5.5.2-11 2.6-16a6.7 6.7 0 0 1 2.7-3.4c.8-.4 1.8-.2 2.3.6.4.8.2 1.8-.7 2.2-.5.3-1 1.1-1.3 1.8-2.1 4.5-2.9 9.5-2.3 14.4.2.9-.5 1.7-1.4 1.8h-.2z"/>
        <g fill-rule="nonzero">
          <path fill="#3D312D" d="M332 174.5c3.5.3 7 .5 10.6 1v-1c-3.5-8.5-6.2-19.7-10.2-28h-.6a130 130 0 0 0-24.2-1.3 579.8 579.8 0 0 1-99 1.5c-2.8 7.2-10 22-15.3 27.7l8.4.2c10.6.2 21 .7 31.8 1 32.1.8 64.6-3.2 98.4-1z"/>
          <path fill="#FFF" d="M306.8 186.7s.1 7.3.3 10.6c3.4 0 7.8 0 11.1-.2.1-3.6-.2-7.2-1-10.7-3.3.3-7 .2-10.4.3zM280.7 186.8c-.2 3.8-.5 6.9 0 10.6 0 .2 8 0 11.1-.1a47 47 0 0 0-.5-10.1c-3.4 0-7.2-.4-10.6-.4z"/>
          <path fill="#005CD7" d="M233.5 175.6c-10.7-.3-21.2-.8-31.8-1v37.1c13.9 0 30.1-.1 47.1-.4-1-8.1-.6-16.9-1.8-25 4.7.5 10.2.2 15 .3-.6 8.2-.6 16.4 0 24.5h-.8l71.1-1.2v-.2c1-8.6.6-26.5-.4-35.2-33.8-2.1-66.3 1.9-98.4 1zm-1.5 23.3c-3.2.2-8.3 0-11.5 0 .2-3 .2-7.4.1-10.5l10.7.1c.4 3.5.6 7 .7 10.4zm59.8-1.6c-3.2 0-11 .3-11 .1-.6-3.8-.3-6.8-.1-10.6 3.4 0 7.2.4 10.6.4.6 3.3.7 6.7.5 10zm26.4-.2c-3.3.2-7.7.3-11 .2-.3-3.3-.4-10.6-.4-10.6 3.4-.1 7.1 0 10.5-.3.7 3.5 1 7.1 1 10.7z"/>
          <path fill="#FFF" d="M262 186.6c-4.9 0-10.3.3-15-.3 1.2 8.2.8 16.9 1.8 25v.1l12.4-.2h.7c-.5-8.2-.5-16.4 0-24.6zM220.6 188.4V199H232c0-3.5-.3-7-.7-10.5 0 0-7.4-.2-10.7-.1z"/>
        </g>
        <g fill-rule="nonzero">
          <path fill="#3D312D" d="M286.2 285.2h-2.7c.6 4.6 0 2.8.7 7.3.4 2.8 1.4 8.6.9 9.5-.6 1-3 1.3-4 1.6-.6.3-1.2.6-1.4 1.3v.6l.3.3c1.4.5 3.5-.4 5-.6.6 0 1.4-.2 2-.4.7-.3 1.9-1.6 1.8-2.2-.4-8.2-1-9-.7-17.5l-2 .1zM275.8 285.3c-1 7 .4 10.4-1.3 17.3 0 0-.2.5-.4.5l-2 .3c-.5 0-1.9.5-2.2.8-.2.4-.3.5 0 .9.2.4.6.8 1 .8 2.4 0 4.5.3 6.3-.6.4-.2 1.3-2.3 1.3-2.8l.2-.5c2.4-6.7.2-10 2.1-16.7h-5zM286.4 258l-1-2c-.2-.6-.3-1.3-.3-2 .2 0 .5-.1.7-.3.6-.6 1.1-1.3 1.3-2.2.5-1.3.8-2.8.5-4.2-.2-1-.2-1.8-.8-2.6a4.5 4.5 0 0 0-1.8-1.5c-1.6-.5-3.7 1.1-4.5 2.3a6.3 6.3 0 0 0-.6 5.5 5 5 0 0 0 2.3 2.9l-1.2 3.9-.2.5c.6-.6 2.5-.5 3.4-.7.7 0 1.5.1 2.2.4z"/>
          <path fill="#0048A9" d="M288.1 285c1.2 0 2.5-.2 2.7-.4.1.3-2.6-16.4-3.2-22.7 0-1.7-1-3.2-1.2-4-.7-.2-1.4-.3-2.2-.3-.9.1-2.8 0-3.4.7-.8.8-.7 1-1 2l-.3.9a8 8 0 0 1-.6.4l-2.2 1.6 2.8 2.5-1.3.8-.5 3.2c-1 5.1-4 10.3-4.9 15.5h10.6a242.6 242.6 0 0 0 4.7-.1z"/>
          <path fill="#3D312D" d="M279.5 265.8l-2.8-2.6-.5.3c-1 .7-2.6 1-3.7 1.5-2.2-.6-5.7-2.3-7.8-3.5-.6-.4-1.7-.5-2.2 0-.4.5-.4 1-.2 2 .3.7.8 1.1 1.6 1.5l1.8.7 6.2 2.3 1.8.6h1.4c.6-.5 1.8-1.3 3.1-2l1.3-.8z"/>
        </g>
        <path fill="#345150" fill-rule="nonzero" d="M161.2 303c-1 0-1.7-.7-1.8-1.6-.2-4.9-1.7-9.7-4.2-14-.3-.5-.3-1.1 0-1.6.4-.5 1-.8 1.6-.8.7 0 1.2.3 1.5.9 2.8 4.7 4.4 10 4.7 15.4 0 .9-.7 1.7-1.7 1.7zM156.3 303c-.6 0-1.2-.3-1.5-.8a33.2 33.2 0 0 0-5.3-6.4c-.4-.4-.6-1-.4-1.6.1-.6.6-1 1.2-1.1.6-.2 1.2 0 1.7.4 2.2 2.1 4.1 4.5 5.8 7 .4.8.2 1.8-.6 2.3l-1 .2zM166.2 302h-.2c-.5 0-.9-.3-1.2-.6-.2-.3-.4-.7-.3-1.1.3-2.1.1-4.3-.4-6.3-.2-.6 0-1.2.4-1.6.4-.4 1-.5 1.7-.3.6.1 1 .6 1.1 1.1.7 2.4.9 5 .6 7.4-.2.8-.9 1.4-1.7 1.4z"/>
        <path d="M151.1 132.2c-1.4-.3-2.7-.7-4-1.2-4.8-1.7-7.5-1.9-13-1.7h-2.9c3.7 5.1 10.7 16.7 13.8 22 3-5.8 8.6-6.3 6.1-19z"/>
        <path fill="#3D312D" fill-rule="nonzero" d="M145 151.3c-3-5.3-10.1-16.9-13.8-22h-5c6.1 8.3 2.7 5 8.5 13.8 2.2 5.8 6.2 9.6 7.6 13.5l.7 3.2c0 5-2.7 9.6-2.9 14.6-.4 15.4-9.1 29.2-11.8 44.3l16.7-3.9c.4 0-3.4-3-3.7-6-1-10.6 4.1-19.1 7-29 3-4.7 10.6-7.6 16-10.4 3.4-1.7 11-5.8 14.6-9.8-1.8-.1-3.5-.2-5.3 0-.4 2-10.3 6.3-12.5 7-2.8 1-10.4 2.2-11 5.2.2-1.6.3-3.3.3-5-.1-15.3 9.9-15.8 9.8-33.4-3-.1-6-.5-9-1.2 2.4 12.8-3.1 13.3-6.2 19z"/>
        <path fill="#345150" fill-rule="nonzero" d="M208 107c-.4-1-1-1.8-1.3-2.8-.3-1-2.5-8.4-3.2-9.7-6.5-12-18-8.2-24.8-7.7-6 .5-11.9 1.8-17.9 2.3-2.8.3-5.8.4-8.4-.6-2-.8-3.6-2.2-5.4-3.3a30.6 30.6 0 0 0-16.8-3.1c-8 .3-15.9 2-22.5 5.6-6.7 3.5-12 9-13.9 15.2-2 6.6-.1 13.6 4.9 18.3a32.8 32.8 0 0 0 17.4 7.5 72.2 72.2 0 0 0 15.1.7l3-.1c5.3-.2 8 0 12.8 1.7 1.4.5 2.7 1 4.1 1.2 3 .7 6 1 9 1.2 7.5.3 15.4-.4 21.9-.8a47 47 0 0 0 18.1-3.9c8.6-4.4 12.2-14.3 8-21.8zM178.9 159.6c2 .2 4.2.4 6.3 0 .6 0 1.4-.2 1.8-.6.6-.6.7-1.4.8-2.1.2-2.1.3-4.7-1.5-5.9-1.3-.9-3.1-.6-4.6-1.1-2.2-.8-3.5-3-5.4-4.3a7.8 7.8 0 0 0-7-.2 15 15 0 0 0-5.7 4.6 10.3 10.3 0 0 0-2.5 5.4c-.1 2 1 4.2 2.8 4.8 2.1.6 5.4.5 7.4-.2l2.3-.4c1.7-.2 3.5-.1 5.3 0z"/>
        <path fill="#BA6740" fill-rule="nonzero" d="M66 257.4s30.7-43.8 91.2-45.5l61.7-.4 43.1-.5-4 3.8s-2.4 5-34.5 7.8c0 0-36.3 2.4-48.7 3.7-28.9 3-39.9 14.3-50.3 22.1-4.4 3.3-32.4 26.7-30.7 54.6-8.2-9.9-14-17.7-17.4-23.6-4.2-7.4-7.7-14.7-10.4-22z"/>
        <g fill-rule="nonzero">
          <path fill="#3D312D" d="M234.6 259c-.4-.7-.7-1-1.7-.7-.7.1-1.8.9-2.5 1.4-1 .6-4.2 2.7-5.3 2.4-1.1-.2-1.5-1.7-2-2.8l-.5-1-3.4 3c1.2 2 2.6 4.6 4.4 5.2 1.9.6 3.8-1 5.5-2 1.5-.8 3.6-1.9 4.7-3 .6-.4 1.6-1.2.8-2.6z"/>
          <path fill="#F5E372" d="M222.3 257.5c-.9-1.6-2.6-2.5-4-3.8l-.5-.6-.3.5-.8.2c-1.8 0-3.6-.6-5-1.8l-1-.8.1.6c0 .7-.2 1.3-.7 1.7-.4.4-1 .7-1.7.7h-.7c-.6 0-1.2-.2-1.6-.6l-.4-.5a14.5 14.5 0 0 0-2.6 1.9l2.2 3c.9-.8 2-1.3 3-1.5.1 1 0 2-.2 3-1.2 4.4-1.3 5.4-1.4 5.7l-1.5 2.9.8-1.5c.1-.3.5-.3.7-.4 1.3-.4 2.6.5 3 1.7a4 4 0 0 1-.8 4c-.5.4-1 .8-1.8 1l-.4.2-.3.1c-1.2.7-2.6.3-3.3-.8l-2 3.9 1.7 1.7 3.7 3.9h.3l4-.9 6-1.2 1.1-.2 2.6-.5-1-3.1c0 1-.2 1-.9 1.7a3 3 0 0 1-2.8 1l-2.4-1.2c-1.6-.8-3-2.3-2.6-4.3.5-2 2.4-2.8 4.3-2.8.8 0 1.8-.3 2.4-.1l-1.8-5.2c-1.2.7-2.7.4-3.5-.7-.7-1.2-1.5-2.8-1-4.3.4-1.7 2.7-3.2 4.4-2.1.4.2.7.6 1 1l.3-.3c.7-.2 1.4 1 2.3 2.6l3.4-3a10 10 0 0 0-.3-.8zM207.5 252.2h.1zM206.8 252.5z"/>
          <path fill="#3D312D" d="M212.8 285.5c.7 1.7.7 3.5.8 5.3.3 3.3 1.5 8.2 1.7 9.8.2 1-.2 2.4 2.6 2.3.1 0 4.2 0 4.3-.6.3-.9-.4-1.5-1.3-1.7-1.3-.4-2.7-.4-2.8-.8 0-.2.6-6 .4-9.6-.4-3.5-1-7-1.7-10.4l-6 1.2c.2.4 1.9 4 2 4.5zM216.7 253.8c.3 0 .5 0 .8-.2l.4-.5-.8-1c-.2-.5.2-2.3.3-3 .7-.1 1.5-.5 2-1 .9-.8 1-2 1.2-3.1 0-1.6.1-2.4-.4-3.8-.7-1.9-3-1.6-4.2-1l-1.9 1.4c-.8.8-1.3 1.9-1.5 3a5 5 0 0 0 .7 3.3c.2.4.5.7.9 1l-.3.3c-.4.7-1.4 1.2-1.8 1.5l-1.2.4h-.1l1 1a7.3 7.3 0 0 0 4.9 1.7z"/>
          <path fill="#3D312D" d="M217.6 239.5c0-.1 1.2-3.2-1-3.2-.7 0-1 0-1.6.2-.8.5-1 .8-1 1.4 0 3 2.8 2 3.6 1.6zM202.8 278c-.5 1-1.8 5.8-2.7 6.2-2.2 1-5.6 1.2-9.2 2.2-1.2.4-2.8 1-2.8 1.8l.2 2.4c.1 2 1 3.3 2.6 3.5l.4-.1v-.4c0-2.2-.2-2.9.2-3.4.6-.7 9-.5 12.2-2 1-.4 2.5-5 3.2-6.3v-.1h-.3l-3.8-3.8zM201.5 256.5l-2.5 2.4c.1 3.3-.8 6-1.3 9.2l-.4 1.4c-.5 1.1 0 2.3.5 2.7.8.5 2 .6 2.8-1l.3-1.1c0-1.2 1.2-6.8 1.5-9.2.8-1.1 1.8-2.1 2.9-3l-2.2-3-1.6 1.6z"/>
          <path fill="#D8623B" d="M206.4 273.2l.3-.1.4-.2c.7-.2 1.3-.6 1.8-1.1a4 4 0 0 0 .8-4c-.4-1.1-1.7-2-3-1.6-.2 0-.6.1-.7.4l-.8 1.5-2.1 4.3a2.4 2.4 0 0 0 3.3.8z"/>
          <path fill="#FF9200" d="M215.6 258c-1.7-1-4 .5-4.5 2.1-.4 1.5.4 3.1 1.1 4.3a2.6 2.6 0 0 0 3.5.7l-.4-1.2.3-1.6 1-3.2c-.3-.5-.6-.8-1-1.1z"/>
          <path fill="#DAA54F" d="M215.1 270.4c-1.9 0-3.8.8-4.2 2.8-.5 2 1 3.5 2.5 4.3.8.4 1.6 1 2.4 1.1a3 3 0 0 0 2.8-1c.7-.6.8-.7.8-1.6l-.7-2.2-1.2-3.5c-.6-.2-1.6.1-2.4.1zM207.7 254.3h.7c.7-.1 1.3-.3 1.7-.8.5-.4.7-1 .7-1.7v-.6c-1 .1-1.9.4-2.8.8l-.4.1-.8.4-1.1.6c0 .2.2.4.3.5.5.5 1.1.7 1.7.7z"/>
        </g>
        <path fill="#BA6740" fill-rule="nonzero" d="M281.7 320c-12.4 0-24.7-1-36.7-2-5.7-.4-11.5-1-17.2-1.3-13.7-.9-28.8-1.2-47.5-1.1-16.8 0-33.8.5-50.6 1.3-1.4 0-2.6-1-2.7-2.5 0-1.4 1-2.6 2.5-2.7 16.8-.7 34-1.2 50.8-1.3a691 691 0 0 1 65 2.5c15.5 1.2 31.4 2.5 46.9 1.7 11.7-.7 23.2-2.5 34.5-5.5a2.6 2.6 0 0 1 1.4 5 175.8 175.8 0 0 1-46.4 5.9z"/>
      </g>
    </svg>
  `
}
