module.exports={
  "Loading": "Loading",
  "Ouch": "Ouch",
  "We apologize, an error has occured on our site.": "We apologize, an error has occured on our site.",
  "Try again": "Try again",
  "Oops": "Oops",
  "the homepage": "the homepage",
  "There is no page at this address. Try finding your way using the menu or from %s.": "There is no page at this address. Try finding your way using the menu or from %s.",
  "Read more": "Read more",
  "Home page": "Home page",
  "Go to homepage": "Go to homepage",
  "Show all projects": "Show all projects",
  "Show more": "Show more",
  "Show more news": "Show more news",
  "All news": "All news",
  "Location": "Location",
  "Supervisor": "Supervisor",
  "Status": "Status",
  "Click on a target to view related projects.": "Click on a target to view related projects.",
  "Show more projects": "Show more projects",
  "Send": "Send",
  "Contributing to these": "Contributing to these",
  "Global Goals": "Global Goals",
  "Your name": "Your name",
  "Further info": "Further info",
  "optional": "optional",
  "LinkedIn profile": "LinkedIn profile",
  "Please let us know why youâre interested in joining the network": "Please let us know why youâre interested in joining the network",
  "Email": "Email",
  "Select your skill": "Select your skill",
  "Select one": "Select one",
  "How much would you like to donate?": "How much would you like to donate?",
  "Donate": "Donate",
  "First name": "First name",
  "Last name": "Last name",
  "Something went wrong. Please, ensure that everything is filled in correctly before trying again.": "Something went wrong. Please, ensure that everything is filled in correctly before trying again.",
  "FÃ¶rnamn": "FÃ¶rnamn",
  "Efternamn": "Efternamn",
  "E-post": "E-post",
  "Personnummer": "Personnummer",
  "Bank": "Bank",
  "Kontonummer": "Kontonummer",
  "Clearingnummer": "Clearingnummer",
  "Hur mycket vill du donera?": "Hur mycket vill du donera?",
  "Skicka": "Skicka"
}